
import { defineComponent } from 'vue'

import BaseInputText from '@/components/common/BaseInputText.vue'
import { InputMode } from '@/components/common/constants/InputMode'

export default defineComponent({
  name: 'FieldUnitPrice',
  components: {
    BaseInputText,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: { type: [String, Number], default: undefined },
    mode: {
      type: Number,
      validator: (value: InputMode) =>
        [InputMode.INPUT, InputMode.READONLY, InputMode.EDIT].includes(value),
      default: InputMode.INPUT,
    },
    isShownLabel: {
      type: Boolean,
      default: true,
    },
    isShortLabel: {
      type: Boolean,
      default: false,
    },
    isAllowMinus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    underRule(): string {
      return this.isAllowMinus ? '-999.999' : '0.001'
    },
    label(): string | undefined {
      if (!this.isShownLabel) {
        return undefined
      }
      return this.isShortLabel
        ? this.$t('trading.label.unitPriceShort').toString()
        : this.$t('trading.label.unitPrice').toString()
    },
  },
  emits: ['input', 'edit'],
  methods: {
    onInput(event: Event) {
      this.$emit('input', event)
    },
    onEdit() {
      this.$emit('edit')
    },
  },
})
