import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-257a6aec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-form" }
const _hoisted_2 = { class: "product-form__input" }
const _hoisted_3 = { class: "product-form__input" }
const _hoisted_4 = { class: "product-form__input" }
const _hoisted_5 = { class: "product-form__input" }
const _hoisted_6 = {
  key: 0,
  class: "product-form__input"
}
const _hoisted_7 = { class: "product-form__input" }
const _hoisted_8 = { class: "product-form__input" }
const _hoisted_9 = { class: "product-form__input" }
const _hoisted_10 = { class: "product-form__standard-delivery-terms" }
const _hoisted_11 = {
  key: 3,
  class: "product-form__input"
}
const _hoisted_12 = { class: "product-form__volume-check-box" }
const _hoisted_13 = { class: "product-form__caption" }
const _hoisted_14 = {
  key: 4,
  class: "product-form__swing-option"
}
const _hoisted_15 = { class: "product-form__swing-option__checkbox" }
const _hoisted_16 = { class: "product-form__caption" }
const _hoisted_17 = {
  key: 0,
  class: "product-form__swing-option__content"
}
const _hoisted_18 = { class: "product-form__input" }
const _hoisted_19 = {
  key: 5,
  class: "product-form__input"
}
const _hoisted_20 = {
  key: 0,
  class: "product-form__caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_radio = _resolveComponent("base-input-radio")!
  const _component_base_input_checkbox = _resolveComponent("base-input-checkbox")!
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_spread_preset_buttons = _resolveComponent("spread-preset-buttons")!
  const _component_non_standard_delivery_terms_form = _resolveComponent("non-standard-delivery-terms-form")!
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_standard_delivery_terms_form = _resolveComponent("standard-delivery-terms-form")!
  const _component_base_input_single_checkbox = _resolveComponent("base-input-single-checkbox")!
  const _component_base_text_area = _resolveComponent("base-text-area")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_radio, {
        label: _ctx.$t('trading.label.position'),
        name: "position",
        value: _ctx.formValue.position,
        options: _ctx.selectableInputs.getPositionInputOptions(_ctx.positionReversed),
        mode: _ctx.positionInputMode,
        required: "",
        onInput: _cache[0] || (_cache[0] = 
          event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, position: event })
        )
      }, null, 8, ["label", "value", "options", "mode"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_input_radio, {
        label: _ctx.$t('trading.label.deliveryUnit'),
        name: "deliveryUnit",
        value: _ctx.formValue.deliveryUnit,
        options: _ctx.selectableInputs.deliveryUnitInputOptions,
        mode: _ctx.deliveryUnitInputMode,
        help: _ctx.$t('trading.message.deliveryUnitHelpText'),
        required: "",
        onInput: _ctx.onDeliveryUnitInput
      }, null, 8, ["label", "value", "options", "mode", "help", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_input_checkbox, {
        label: _ctx.$t('trading.label.productType'),
        name: "productTypes",
        value: _ctx.formValue.productTypeIds,
        options: _ctx.selectableInputs.productTypeInputOptions,
        togglable: _ctx.productTypesTogglable,
        mode: _ctx.productTypesInputMode,
        "initial-values": _ctx.formValue.productTypeIds,
        "ignore-options-on-toggle": _ctx.selectableInputs.unavailableProductTypeIds,
        "active-all-label": _ctx.$t('trading.label.activeAllProductTypeLabel'),
        required: "",
        rules: "required",
        onInput: _cache[1] || (_cache[1] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, productTypeIds: event })
        )
      }, null, 8, ["label", "value", "options", "togglable", "mode", "initial-values", "ignore-options-on-toggle", "active-all-label"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.selectableInputs.hasFuelSurcharge)
        ? (_openBlock(), _createBlock(_component_base_single_select, {
            key: 0,
            label: _ctx.$t('trading.label.fuelSurchargeType'),
            name: "fuelSurchargeType",
            value: 
          _ctx.selectableInputs.getFuelSurchargeInputOptionById(
            _ctx.formValue.fuelSurchargeTypeId,
          )
        ,
            options: _ctx.selectableInputs.fuelSurchargeTypeInputOptions,
            placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.fuelSurchargeType'),
          })
        ,
            mode: _ctx.fuelSurchargeTypeInputMode,
            rules: "required",
            required: "",
            onInput: _ctx.onFuelSurchargeTypeInput
          }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isShownSpreadPreset)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_spread_preset_buttons, {
            "selected-product-types": _ctx.selectedProductTypes,
            onClick: _ctx.onSpreadPresetClick
          }, null, 8, ["selected-product-types", "onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isNonStandardType)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_base_single_select, {
              label: _ctx.$t('trading.label.area'),
              name: "area",
              value: 
            _ctx.selectableInputs.getAreaInputOptionById(_ctx.formValue.base.areaId)
          ,
              options: _ctx.selectableInputs.areaInputOptions,
              placeholder: 
            _ctx.$t('common.message.selectPlaceholder', {
              name: _ctx.$t('trading.label.area'),
            })
          ,
              mode: _ctx.areaInputMode,
              rules: "required",
              required: "",
              onInput: _ctx.onAreaInput
            }, null, 8, ["label", "value", "options", "placeholder", "mode", "onInput"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_non_standard_delivery_terms_form, {
              "form-value": _ctx.nonStandardDeliveryTermsFormValue,
              locale: _ctx.locale,
              "day-pattern-options": _ctx.dayPatternOptions,
              "has-swap-eex": _ctx.hasSwapEex,
              "unselectable-start-delivery-days-of-week": 
            _ctx.unselectableStartDeliveryDaysOfWeek
          ,
              "unselectable-end-delivery-days-of-week": 
            _ctx.unselectableEndDeliveryDaysOfWeek
          ,
              "start-delivery-min-date": _ctx.startDeliveryMinDate,
              "start-delivery-max-dates": _ctx.startDeliveryMaxDates,
              "end-delivery-min-dates": _ctx.endDeliveryMinDates,
              mode: _ctx.mode,
              onFormInput: _cache[2] || (_cache[2] = 
            event => _ctx.$emit(_ctx.formInputEventName, { ..._ctx.formValue, ...event })
          )
            }, null, 8, ["form-value", "locale", "day-pattern-options", "has-swap-eex", "unselectable-start-delivery-days-of-week", "unselectable-end-delivery-days-of-week", "start-delivery-min-date", "start-delivery-max-dates", "end-delivery-min-dates", "mode"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.isShownSpreadPreset)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "product-form__clear-link",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSpreadPresetClear && _ctx.onSpreadPresetClear(...args)))
              }, _toDisplayString(_ctx.$t('trading.label.spreadClear')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_standard_delivery_terms_form, {
                "form-value": _ctx.standardDeliveryTermsFormValue,
                "selectable-inputs": _ctx.selectableInputs,
                "opened-forms": _ctx.openedSpreadFormTypes,
                "delivery-unit": _ctx.formValue.deliveryUnit,
                locale: _ctx.locale,
                "area-input-mode": _ctx.areaInputMode,
                "hour-type-input-mode": _ctx.hourTypeInputMode,
                "start-delivery-year-month-input-mode": 
              _ctx.startDeliveryYearMonthInputMode
            ,
                "end-delivery-year-month-input-mode": _ctx.endDeliveryYearMonthInputMode,
                "volume-input-mode": _ctx.volumeInputMode,
                "delivery-date-form-mode": _ctx.deliveryDateFormMode,
                "date-names": _ctx.dateNames,
                "selected-product-types": _ctx.selectedProductTypes,
                onFormInput: _ctx.onStandardDeliveryTermsFormInput,
                onTextInput: _ctx.onStandardDeliveryTermsTextInput,
                onOpen: _ctx.onStandardDeliveryTermsFormOpen,
                onClose: _ctx.onStandardDeliveryTermsFormClose
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_input_text, {
                    name: "unitPrice",
                    value: _ctx.formValue.unitPrices[0],
                    "value-type": "number",
                    mode: _ctx.unitPriceInputMode,
                    help: _ctx.$t('trading.message.unitPriceHelpText'),
                    label: this.$t('trading.label.unitPrice'),
                    placeholder: 
                _ctx.$t('common.message.inputPlaceholder', {
                  name: _ctx.$t('trading.label.unitPriceShort'),
                })
              ,
                    "validation-error-message": 
                _ctx.selectableInputs.unitPrice.validationErrorMessage
              ,
                    rules: "required",
                    required: "",
                    onInput: _cache[4] || (_cache[4] = 
                event =>
                  _ctx.$emit(_ctx.textInputEventName, {
                    ..._ctx.formValue,
                    unitPrices: [event],
                    price: {
                      ..._ctx.formValue.price,
                      energyUnitPrices: [event],
                    },
                  })
              )
                  }, null, 8, ["value", "mode", "help", "label", "placeholder", "validation-error-message"])
                ]),
                _: 1
              }, 8, ["form-value", "selectable-inputs", "opened-forms", "delivery-unit", "locale", "area-input-mode", "hour-type-input-mode", "start-delivery-year-month-input-mode", "end-delivery-year-month-input-mode", "volume-input-mode", "delivery-date-form-mode", "date-names", "selected-product-types", "onFormInput", "onTextInput", "onOpen", "onClose"])
            ])
          ])
        ], 64)),
    (_ctx.isBroker && !_ctx.selectableInputs.isSpread)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_base_input_single_checkbox, {
              label: _ctx.$t('trading.label.toDisplayVolumeAsTbd'),
              value: _ctx.formValue.maskVolume,
              mode: _ctx.requestInputMode,
              onInput: _ctx.onMaskVolumeInput
            }, null, 8, ["label", "value", "mode", "onInput"]),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('trading.message.explanationOfCheckBoxOfVolume')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isJeraFy24 && !_ctx.selectableInputs.isSpread)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_base_input_single_checkbox, {
              label: _ctx.$t('trading.label.inputSwingOption'),
              mode: _ctx.basicUnitPriceInputMode,
              value: _ctx.isSwingOption,
              onInput: _ctx.onEnableSwingOptionInput
            }, null, 8, ["label", "mode", "value", "onInput"]),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('trading.message.explanationOfSwingOption')), 1)
          ]),
          (_ctx.isSwingOption)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_component_base_input_text, {
                  class: "product-form__swing-option__content__input",
                  name: "basicUnitPrice",
                  "value-type": "number",
                  value: _ctx.formValue.price.basicUnitPrice?.unitPrice ?? '',
                  "validation-error-message": 
            _ctx.selectableInputs.price.basicUnitPrice?.unitPrice
              .validationErrorMessage
          ,
                  mode: _ctx.basicUnitPriceInputMode,
                  help: _ctx.$t('trading.message.basicUnitPriceHelpText'),
                  label: 
            _ctx.$t('trading.label.basicUnitPriceLabelWithUnit', {
              unit: _ctx.$t('trading.label.basicUnitPriceUnit.jpyPerKw'),
            })
          ,
                  placeholder: 
            _ctx.$t('common.message.inputPlaceholder', {
              name: _ctx.$t('trading.label.basicUnitPrice'),
            })
          ,
                  onInput: _ctx.onBasicUnitPriceInput
                }, null, 8, ["value", "validation-error-message", "mode", "help", "label", "placeholder", "onInput"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(_component_base_text_area, {
        label: _ctx.$t('trading.label.request'),
        value: _ctx.formValue.request,
        mode: _ctx.requestInputMode,
        rules: _ctx.maxLengthOfTextArea,
        onInput: _cache[5] || (_cache[5] = 
          event => _ctx.$emit(_ctx.textInputEventName, { ..._ctx.formValue, request: event })
        )
      }, null, 8, ["label", "value", "mode", "rules"])
    ]),
    (_ctx.isDisplayPublicInformation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_base_text_area, {
            label: _ctx.$t('trading.label.publicInformation'),
            value: _ctx.formValue.publicInformation,
            mode: _ctx.publicInformationMode,
            rules: _ctx.maxLengthOfTextArea,
            onInput: _cache[6] || (_cache[6] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              publicInformation: event,
            })
        )
          }, null, 8, ["label", "value", "mode", "rules"]),
          (_ctx.isBroker)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t('trading.message.explanationOfPublicInformation')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}