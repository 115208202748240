
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { UpdateDeal } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { LayoutMode } from '@/components/common/constants/LayoutMode'
import { PER_PAGE } from '@/components/common/constants/List'
import { UiStack } from '@/components/common/constants/UiStack'
import { ActionDropdownItem } from '@/components/common/interface/ActionDropdownProps'
import { InputOption } from '@/components/common/interface/InputOption'
import DealList from '@/components/trading/DealList.vue'
import DealListHeader from '@/components/trading/DealListHeader.vue'
import ProductFilter from '@/components/trading/ProductFilter.vue'
import { ChangePrivateConfidential } from '@/components/trading/interface/DealListItemEmitters'
import { AttributeFilter } from '@/components/trading/interface/ProductFilterProps'
import { DealSummary } from '@/models/trading/DealSummary'
import { DealWithOrganizationRecap } from '@/models/trading/DealWithOrganizationRecap'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { DealModule } from '@/store/modules/trading/deal'
import { SET_FILTER_CONDITION } from '@/store/modules/trading/deal/DealMutations'
import { getDealsFilter } from '@/store/modules/trading/deal/helper'
import { DealFilterAttribute } from '@/store/modules/trading/deal/interface'
import { ProductModule } from '@/store/modules/trading/product'
import { RecapModule } from '@/store/modules/trading/recap'
import { convertRecapStateSummary } from '@/store/modules/trading/recap/helper'
import {
  RecapBulkResponse,
  RecapState,
} from '@/store/modules/trading/recap/interface'
import { setNotification } from '@/utils/utils'

const {
  mapState: dealMapState,
  mapActions: dealMapActions,
  mapMutations: dealMutations,
  mapGetters: dealMapGetters,
} = createNamespacedHelpers('deal') as DealModule

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const { mapActions: recapMapActions } = createNamespacedHelpers(
  'recap',
) as RecapModule

const keyOfSelectedFilters = 'selectedFiltersOfDeals'

export default defineComponent({
  name: 'Deals',
  components: {
    BaseAlert,
    BrokerPage,
    CenteredLoadingContent,
    DealList,
    DealListHeader,
    ProductFilter,
    UiStackSelector,
  },
  data(): {
    layoutMode: LayoutMode
    deals: DealSummary[]
    isLoaded: boolean
    uiStack: UiStack
    alertTypeInfo: AlertType.Info
    alertTypeError: AlertType.Error
    attributes: DealFilterAttribute[]
    selectedFilters: DealFilterAttribute[]
    isFilterActionDropdownActive: boolean
    idsOfDisabledCheckbox: string[]
    recapStateSummary: RecapState
  } {
    return {
      layoutMode: LayoutMode.Wide,
      deals: [],
      isLoaded: false,
      uiStack: UiStack.Loading,
      alertTypeInfo: AlertType.Info,
      alertTypeError: AlertType.Error,
      attributes: [
        'area',
        'startDeliveryYearMonth',
        'endDeliveryYearMonth',
        'hourType',
        'productType',
        'organizationName',
        'privateConfidentialOfFilter',
        'negotiationId',
        'executedDate',
      ],
      selectedFilters: [] as DealFilterAttribute[],
      isFilterActionDropdownActive: false,
      idsOfDisabledCheckbox: [],
      recapStateSummary: {},
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState([
      'areas',
      'hourTypes',
      'productTypes',
      'deliveryYearMonths',
    ]),
    ...dealMapState(['filterCondition']),
    ...dealMapGetters(['filterConditionParameter']),
    lastId(): string | undefined {
      if (this.deals.length === 0) {
        return undefined
      }
      return this.deals[this.deals.length - 1].id
    },
    filters(): AttributeFilter[] {
      return getDealsFilter(
        this.areas,
        this.hourTypes,
        this.productTypes,
        this.selectedFilters,
        this.filterCondition,
        this.deliveryYearMonths,
      )
    },
    filterActions(): ActionDropdownItem[] {
      return this.attributes.map(attribute => ({
        label: this.$t('trading.label.' + attribute),
        eventName: attribute,
      })) as ActionDropdownItem[]
    },
  },
  async created() {
    await Promise.all([
      this.fetchInitialList(),
      this.fetchAreas(),
      this.fetchHourTypes(),
      this.fetchProductTypes(),
      this.fetchDeliveryYearMonths(),
    ])
    this.getFilters(keyOfSelectedFilters)
  },
  methods: {
    fetchDealSummaries: dealMapActions(['fetchDealSummaries'])
      .fetchDealSummaries as (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any,
    ) => Promise<DealSummary[]>,
    updateDealWithOrganizationRecap: dealMapActions([
      'updateDealWithOrganizationRecap',
    ]).updateDealWithOrganizationRecap as (payload: {
      id: string
      updateDeal: UpdateDeal
    }) => Promise<DealWithOrganizationRecap>,
    ...dealMutations([SET_FILTER_CONDITION]),
    ...productMapActions([
      'fetchAreas',
      'fetchHourTypes',
      'fetchProductTypes',
      'fetchDeliveryYearMonths',
    ]),
    ...recapMapActions(['fetchBulkRecap']),
    async fetchInitialList() {
      this.deals = []
      this.isLoaded = false
      try {
        const deals: DealSummary[] = await this.fetchDealSummaries(
          this.filterConditionParameter,
        )
        this.deals = deals
        this.isLoaded = deals.length === 0 || deals.length % PER_PAGE !== 0

        const token = String(await this.$auth.getTokenSilently())
        const recaps = (await this.fetchBulkRecap({
          token,
          identifiers: deals.map(deal => deal.id) || [],
        })) as RecapBulkResponse
        this.recapStateSummary = convertRecapStateSummary(recaps)

        if (this.deals.length === 0) {
          this.uiStack = UiStack.Blank
        } else {
          this.uiStack = UiStack.Ideal
        }
      } catch (e) {
        this.uiStack = UiStack.Error
        throw e
      }
    },
    async fetchNextList(lastId?: string) {
      try {
        const deals = await this.fetchDealSummaries({
          ...this.filterConditionParameter,
          lastId,
        })
        this.deals.push(...deals)

        const token = String(await this.$auth.getTokenSilently())
        const recaps = (await this.fetchBulkRecap({
          token,
          identifiers: deals.map(deal => deal.publicNegotiationId) || [],
        })) as RecapBulkResponse
        this.recapStateSummary = {
          ...this.recapStateSummary,
          ...convertRecapStateSummary(recaps),
        }

        this.isLoaded =
          this.isLoaded || deals.length === 0 || deals.length % PER_PAGE !== 0
      } catch (e) {
        this.uiStack = UiStack.Error
        throw e
      }
    },
    onLastItemVisible() {
      if (!this.isLoaded) {
        this.fetchNextList(this.lastId)
      }
    },
    async onConfirm(negotiationId: string) {
      await this.$router.push(`/negotiations/${negotiationId}`)
    },
    onClickFilterDropdown() {
      this.isFilterActionDropdownActive = !this.isFilterActionDropdownActive
    },
    onClickFilterOutside() {
      this.isFilterActionDropdownActive = false
    },
    onChangeFilters(event: {
      selectedValues: DealFilterAttribute[]
      changeValue: DealFilterAttribute
    }) {
      this.selectedFilters = event.selectedValues
      this.updateLocalStorageOfSelectedFilters(
        keyOfSelectedFilters,
        this.selectedFilters,
      )
    },
    getFilters(key: string) {
      const item = localStorage.getItem(key)
      if (item !== null) {
        const selectedFiltersFromLocal = JSON.parse(item)
        this.selectedFilters = selectedFiltersFromLocal
      }
    },
    updateLocalStorageOfSelectedFilters(
      key: string,
      value: DealFilterAttribute[],
    ) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    onFilterItemSelect(event: {
      attribute: DealFilterAttribute
      value?: InputOption
    }) {
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: event.value?.value,
        },
      })
      this.fetchInitialList()
    },
    onFilterRemove(event: { attribute: DealFilterAttribute }) {
      this.selectedFilters = this.selectedFilters.filter(
        attribute => attribute !== event.attribute,
      )
      const isRequiredFetchList = !!this.filterCondition[event.attribute]
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: undefined,
        },
      })
      this.updateLocalStorageOfSelectedFilters(
        keyOfSelectedFilters,
        this.selectedFilters,
      )
      if (isRequiredFetchList) {
        this.fetchInitialList()
      }
    },
    onFilterItemInput(event: {
      attribute: DealFilterAttribute
      value?: string
    }) {
      this[SET_FILTER_CONDITION]({
        filterCondition: {
          ...this.filterCondition,
          [event.attribute]: event.value,
        },
      })
      this.fetchInitialList()
    },
    async onPrivateConfidentialChange(
      changePrivateConfidential: ChangePrivateConfidential,
    ) {
      this.idsOfDisabledCheckbox.push(changePrivateConfidential.id)
      await this.updateDealWithOrganizationRecap({
        id: changePrivateConfidential.id,
        updateDeal: {
          deal: {
            privateConfidential: changePrivateConfidential.privateConfidential,
          },
        },
      })
        .then(async (dealWithOrganizationRecap: DealWithOrganizationRecap) => {
          setNotification(
            this.$t(
              'trading.message.successUpdatePrivateConfidential',
            ).toString(),
          )
          this.deals = this.deals.map(deal => {
            if (deal.id === changePrivateConfidential.id) {
              deal.isPrivateConfidential =
                dealWithOrganizationRecap.deal.privateConfidential
            }
            return deal
          })
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failUpdatePrivateConfidential').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.idsOfDisabledCheckbox = this.idsOfDisabledCheckbox.filter(
            id => id !== changePrivateConfidential.id,
          )
        })
    },
  },
})
