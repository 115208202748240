import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-567004f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["aria-hidden"]
const _hoisted_3 = ["aria-describedby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.tooltipId,
    role: "tooltip",
    class: _normalizeClass(_ctx.tooltipClass),
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onEnterMouse && _ctx.onEnterMouse(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLeaveMouse && _ctx.onLeaveMouse(...args))),
    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.tooltipInnerClass),
      "aria-hidden": !_ctx.isActive || undefined
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.label), 1)
    ], 10, _hoisted_2),
    _createElementVNode("button", {
      type: "button",
      "aria-describedby": _ctx.tooltipId,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_3)
  ], 42, _hoisted_1))
}