
import { defineComponent, PropType } from 'vue'

import { DeliveryUnit, Locale, SpreadTypeTypeEnum } from '@/api/generated'
import BaseInputCheckbox from '@/components/common/BaseInputCheckbox.vue'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseInputSingleCheckbox from '@/components/common/BaseInputSingleCheckbox.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTextArea from '@/components/common/BaseTextArea.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { MAX_LENGTH_OF_TEXT_AREA } from '@/components/common/constants/ValidationRules'
import { InputOption } from '@/components/common/interface/InputOption'
import NonStandardDeliveryTermsForm from '@/components/trading/NonStandardDeliveryTermsForm.vue'
import SpreadPresetButtons from '@/components/trading/SpreadPresetButtons.vue'
import StandardDeliveryTermsForm, {
  OpenableSpreadForms,
} from '@/components/trading/StandardDeliveryTermsForm.vue'
import { IndicationFormInputMode } from '@/components/trading/constants/IndicationFormInputMode'
import { DeliveryPeriodFormProps } from '@/components/trading/interface/DeliveryPeriodFormProps'
import { ProductFormProps } from '@/components/trading/interface/ProductFormProps'
import { StandardDeliveryTermsFormProps } from '@/components/trading/interface/StandardDeliveryTermsFormProps'
import {
  endDeliveryMinDate,
  startDeliveryMaxDate,
  startDeliveryMinDate,
  unselectableEndDeliveryDaysOfWeek,
  unselectableStartDeliveryDaysOfWeek,
} from '@/components/trading/services/deliveryDate'
import { BaseStandardIndicationSelectableInputs } from '@/models/trading/BaseStandardIndicationSelectableInputs'
import { ProductType } from '@/models/trading/ProductType'
import { ProductTypeDateName } from '@/models/trading/ProductTypeDateName'
import { SpreadPresetValue } from '@/models/trading/SpreadPreset'

export default defineComponent({
  name: 'ProductForm',
  components: {
    BaseInputCheckbox,
    BaseInputRadio,
    BaseInputSingleCheckbox,
    BaseInputText,
    BaseLabel,
    BaseSingleSelect,
    BaseTextArea,
    NonStandardDeliveryTermsForm,
    SpreadPresetButtons,
    StandardDeliveryTermsForm,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: IndicationFormInputMode) => {
        return Object.values(IndicationFormInputMode).includes(value)
      },
    },
    locale: {
      type: String as PropType<Locale>,
      required: true,
    },
    positionReversed: {
      type: Boolean,
      default: false,
    },
    selectableInputs: {
      type: Object as PropType<BaseStandardIndicationSelectableInputs>,
      required: true,
    },
    formValue: {
      type: Object as PropType<ProductFormProps>,
      required: true,
    },
    openedSpreadFormTypes: {
      type: Array as PropType<OpenableSpreadForms>,
      default: () => [],
    },
    dayPatternOptions: {
      type: Array as PropType<InputOption[]>,
      default: () => [],
    },
    productTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
    dateNames: {
      type: Array as PropType<ProductTypeDateName[]>,
      required: true,
    },
    isBroker: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'form-input',
    'text-input',
    'standard-delivery-terms-form-open',
    'standard-delivery-terms-form-close',
  ],
  data(): {
    formInputEventName: 'form-input'
    textInputEventName: 'text-input'
    maxLengthOfTextArea: string
  } {
    return {
      formInputEventName: 'form-input',
      textInputEventName: 'text-input',
      maxLengthOfTextArea: MAX_LENGTH_OF_TEXT_AREA,
    }
  },
  computed: {
    isJeraFy24(): boolean {
      return (
        this.formValue.fuelSurchargeTypeId ===
          'aae97462-626d-44ec-8a6d-abcb2701e906' || // JERA燃調-東京
        this.formValue.fuelSurchargeTypeId ===
          '8f5c7b3a-0712-43cc-bf8e-a3025f72d2df' || // JERA燃調-中部1
        this.formValue.fuelSurchargeTypeId ===
          '230d99ac-44d6-4725-b790-c938a20aa91c' // JERA燃調-中部2
      )
    },
    isSwingOption(): boolean {
      return this.formValue.price?.type === 'basic-plus-volume'
    },
    isShownSpreadPreset(): boolean {
      return (
        this.formValue.deliveryUnit !== DeliveryUnit.NonStandard &&
        this.areaInputMode !== InputMode.READONLY &&
        this.hourTypeInputMode !== InputMode.READONLY
      )
    },
    productTypesTogglable(): boolean {
      return this.productTypesInputMode !== InputMode.READONLY
    },
    positionInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    deliveryUnitInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.EDIT,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    productTypesInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    fuelSurchargeTypeInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    areaInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    startDeliveryYearMonthInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    endDeliveryYearMonthInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    deliveryDateFormMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    volumeInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    unitPriceInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    hourTypeInputMode(): InputMode {
      return [
        IndicationFormInputMode.PRODUCT_FIXED,
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    requestInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    basicUnitPriceInputMode(): InputMode {
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    isNonStandardType(): boolean {
      return this.formValue.deliveryUnit === DeliveryUnit.NonStandard
    },
    selectedProductTypes(): ProductType[] {
      return this.productTypes.filter(productType =>
        this.formValue.productTypeIds.includes(productType.productTypeId),
      )
    },
    publicInformationMode(): InputMode {
      if (!this.isBroker) {
        return InputMode.READONLY
      }
      return [
        IndicationFormInputMode.NEGOTIATION,
        IndicationFormInputMode.EXPIRED,
        IndicationFormInputMode.DELETED,
      ].includes(this.mode)
        ? InputMode.READONLY
        : InputMode.INPUT
    },
    hasSwapEex(): boolean {
      return this.selectedProductTypes.some(p => p.isSwapEex())
    },
    unselectableStartDeliveryDaysOfWeek(): number[] {
      return unselectableStartDeliveryDaysOfWeek(this.selectedProductTypes)
    },
    unselectableEndDeliveryDaysOfWeek(): number[] {
      return unselectableEndDeliveryDaysOfWeek(this.selectedProductTypes)
    },
    startDeliveryMinDate(): Date | undefined {
      return startDeliveryMinDate(this.selectedProductTypes)
    },
    startDeliveryMaxDates(): (Date | undefined)[] {
      if (!this.isNonStandardType) {
        return []
      }
      return this.formValue.deliveryPeriods.map(deliveryPeriod => {
        return startDeliveryMaxDate(
          deliveryPeriod.endDeliveryDate,
          this.selectedProductTypes,
        )
      })
    },
    endDeliveryMinDates(): Date[] {
      if (!this.isNonStandardType) {
        return []
      }
      return this.formValue.deliveryPeriods.map(deliveryPeriod => {
        return endDeliveryMinDate(
          deliveryPeriod.startDeliveryDate,
          this.selectedProductTypes,
        )
      })
    },
    standardDeliveryTermsFormValue(): StandardDeliveryTermsFormProps {
      return {
        base: this.formValue.base,
        exchange: this.formValue.exchange,
      }
    },
    nonStandardDeliveryTermsFormValue(): {
      deliveryPeriods: DeliveryPeriodFormProps[]
    } {
      return {
        deliveryPeriods: this.formValue.deliveryPeriods,
      }
    },
    isDisplayPublicInformation(): boolean {
      if (this.isBroker) {
        return true
      }
      return (
        this.formValue.publicInformation !== undefined &&
        this.formValue.publicInformation.length > 0
      )
    },
  },
  methods: {
    onSpreadPresetClick(value: SpreadPresetValue) {
      this.onStandardDeliveryTermsFormOpen(SpreadTypeTypeEnum.Area)
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        base: {
          ...this.formValue.base,
          areaId: value.areaId,
          hourTypeId: value.hourTypeId,
        },
        exchange: {
          ...this.formValue.exchange,
          areaId: value.exchangeAreaId,
          hourTypeId: value.hourTypeId,
        },
      })
    },
    onSpreadPresetClear() {
      this.onStandardDeliveryTermsFormClose(SpreadTypeTypeEnum.Area)
      this.onStandardDeliveryTermsFormClose(SpreadTypeTypeEnum.DeliveryPeriod)
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        base: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: this.formValue.base.volumes,
        },
        exchange: {
          areaId: undefined,
          startDeliveryYearMonthId: undefined,
          endDeliveryYearMonthId: undefined,
          startDeliveryDate: undefined,
          endDeliveryDate: undefined,
          hourTypeId: undefined,
          volumes: this.formValue.exchange.volumes,
        },
      })
    },
    onDeliveryUnitInput(deliveryUnit: DeliveryUnit) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        deliveryUnit,
      })
    },
    onStandardDeliveryTermsFormInput(value: StandardDeliveryTermsFormProps) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        ...value,
      })
    },
    onStandardDeliveryTermsTextInput(value: StandardDeliveryTermsFormProps) {
      this.$emit(this.textInputEventName, {
        ...this.formValue,
        ...value,
      })
    },
    onAreaInput(event: InputOption | null) {
      const areaId = event?.value
      const formValue = Object.assign({}, this.formValue, {
        base: {
          ...this.formValue.base,
          areaId,
        },
      })
      this.$emit(this.formInputEventName, formValue)
    },
    onFuelSurchargeTypeInput(event: InputOption | null) {
      const fuelSurchargeTypeId = event?.value
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        fuelSurchargeTypeId,
      })
    },
    onStandardDeliveryTermsFormOpen(type: SpreadTypeTypeEnum) {
      this.$emit('standard-delivery-terms-form-open', type)
    },
    onStandardDeliveryTermsFormClose(type: SpreadTypeTypeEnum) {
      this.$emit('standard-delivery-terms-form-close', type)
    },
    onMaskVolumeInput(event: boolean) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        maskVolume: event,
      })
    },
    onEnableSwingOptionInput(event: boolean) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        price: {
          ...this.formValue.price,
          type: event ? 'basic-plus-volume' : 'volume',
        },
      })
    },
    onBasicUnitPriceInput(event: string) {
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        price: {
          ...this.formValue.price,
          basicUnitPrice: {
            type: 'jpy-per-kw',
            unitPrice: event,
          },
        },
      })
    },
  },
})
